// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();
require("channels");
require("jquery/dist/jquery.min");
require("flatpickr/dist/flatpickr.min.js");
require("flatpickr/dist/l10n/ja.js");
require("bootstrap/dist/js/bootstrap.min");
require("loaders.css/loaders.css.js");
require("@fortawesome/fontawesome-free/js/all");
// require("chart.js/dist/chart.js");
// require("chartjs-color/index.js");
// require("chartjs-color-string/color-string.js");

import "flatpickr/dist/flatpickr.min.css";
import "bootstrap/dist/css/bootstrap.min";
import "loaders.css/loaders.min.css";
import "@fortawesome/fontawesome-free/scss/fontawesome";

import "my_stylesheets/libraries/stripe";
import "my_stylesheets/libraries/toggle-btn";
import "my_stylesheets/libraries/login";
import "my_stylesheets/base";
import "my_stylesheets/user";
import "my_stylesheets/stripe";
import "my_stylesheets/liff";
import "my_stylesheets/new_liff";
import "my_stylesheets/item";
import "my_stylesheets/item-stats";
import "my_stylesheets/home";
import "my_stylesheets/tag";
import "my_stylesheets/order";
import "my_stylesheets/order-detail";

$(document).on("turbolinks:load", () => {
  setTimeout('$(".alert").fadeOut("slow")', 1000);
});
